import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AnyType } from 'types/anytype';

export interface APIError {
  Message: string;
  Key: string;
}

export interface Response {
  status: 'OK' | 'failed';
  seqId: string;
}

export interface SuccessfulResponse<T> extends Response {
  status: 'OK';
  data: T;
}

export interface FailedResponse extends Response {
  data: unknown;
  status: 'failed';
  errors?: Array<APIError>;
}

function showCommonNotification(message: string): void {
  // eslint-disable-next-line no-console
  console.error(' ---> FAQ API Error ', message);
}

/**
 * Request error handling function. Executes when axios throws an expection
 * @param error – an error from Axios
 * @param handleErrors – manual error handling function
 */
const handleCatch = (
  error: AxiosError,
): FailedResponse => {
  if (error.response) {
    const response: FailedResponse = error.response.data || { status: 'failed', seqId: '' };
    showCommonNotification(
      error.response.data.status,
    );
    return response;
  }

  if (error.request) {
    // if didn't get a response (network issues), show a generic error
    showCommonNotification('Network Generic error');
  } else {
    // if didn't make a request (?), show a generic error
    showCommonNotification('Request Generic error');
  }

  // returns empty failed response anyways
  return {
    seqId: '',
    status: 'failed',
  } as FailedResponse;
};

/**
 * General request method which fires axios and handles response
 * @param axiosConfig – axios config with url, method, data and other custom parameters
 * @param handleErrors – manual error handling function
 */
const request = <T>(
  axiosConfig: AxiosRequestConfig,
): Promise<SuccessfulResponse<T> | FailedResponse> => {
  const headers = {};

  return axios({
    headers,
    withCredentials: false,
    ...axiosConfig,
  }).then((axiosResponse: AxiosResponse) => {
    const response: Response = axiosResponse.data;

    if (response.status === 'OK') {
      return response as SuccessfulResponse<T>;
    }
    if (response.status) {
      showCommonNotification(
        response.status,
      );
    }

    return response as FailedResponse;
  }).catch((error: AxiosError) => handleCatch(error));
};

export const get = <T>(
  url: string,
  params: AnyType = {},
): Promise<SuccessfulResponse<T> | AnyType> => request<T>({
  url,
  params,
  method: 'get',
});

export const api = {
  get,
};
