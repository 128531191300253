/* eslint-disable class-methods-use-this */
import React from 'react';
import { Container } from 'react-bootstrap';
import AnchorMenu, { AnchorMenuLink } from 'components/AnchorMenu';
import { FormattedMessage } from 'react-intl';
import {
  ContentViewDataType,
  SectionContentiewType,
  SectionViewType,
  ArticleViewType,
} from 'types/faq';
import GeneralSettings from 'helpers/constants/settings';

import FAQService from 'services/faq';
import FAQContentWrapper from '../ContentWrapper';
import FAQContent from '../Content';
import styles from './Contents.module.scss';

type ContentProps = {
  searchTerm: string;
  locale: string;
}

interface State {
  content: ContentViewDataType;
  anchorLinks: Array<AnchorMenuLink>;
  screenWidth: number;
  screenHeight: number;
  isLoadError: boolean;
  loadingItems: boolean;
}

export default class FAQContents extends React.Component<ContentProps, State> {
  faqService: FAQService;

  constructor(props: ContentProps) {
    super(props);
    this.state = {
      content: [],
      anchorLinks: [],
      screenWidth: 0,
      screenHeight: 0,
      isLoadError: false,
      loadingItems: false,
    };
    this.faqService = new FAQService();
  }

  componentDidMount(): void {
    const { locale } = this.props;
    this.loadContent('', locale);
    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  }

  componentDidUpdate(prevProps: ContentProps): void {
    const { searchTerm, locale } = this.props;
    if (searchTerm !== prevProps.searchTerm) {
      this.loadContent(searchTerm, locale);
    }
  }

  getQuestions(articlesContent: Array<ArticleViewType>): React.ReactNode {
    const questionsArray = articlesContent.length > 0 ? articlesContent.map((item) => (
      <FAQContent
        key={item.id}
        id={item.id.toString()}
        question={item.title}
        answer={item.body}
      />
    )) : null;
    return questionsArray;
  }

  createAnchorLinks(sections: Array<SectionViewType>): Array<AnchorMenuLink> {
    const list: Array<AnchorMenuLink> = [];
    sections.forEach((item) => {
      const obj = {
        header: { label: item.sectionTitle, hashTag: item.hashTag },
        subMenu: [],
      };
      list.push(obj);
    });
    return list;
  }

  async loadContent(searchText = '', lang: string): Promise<unknown> {
    this.setState({ loadingItems: true });
    const sections = await this.faqService.getSections(lang, true);
    if (sections && sections.data.length < 1) {
      this.setState({ loadingItems: false, isLoadError: true });
      return;
    }

    let response;
    if (searchText && searchText.length > 2) {
      response = await this.faqService.searchArticles(searchText, lang);
      response = response.data;
    } else {
      response = await this.faqService.getSectionsAndTheirArticles(sections.data, lang);
    }

    if (response) {
      this.setState({
        content: response,
        anchorLinks: this.createAnchorLinks(sections.data),
      });
    }
    this.setState({ loadingItems: false });
  }

  render(): React.ReactNode {
    const {
      anchorLinks,
      content,
      screenWidth,
      screenHeight,
      loadingItems,
      isLoadError,
    } = this.state;
    const isSearchView = content.length > 0
      && (content as Array<SectionContentiewType>)[0].sectionTitle === undefined;

    const noResults = (
      <span>
        <FormattedMessage id="components.faq.noResults" />
      </span>
    );

    const loadingMessage = (
      <span>
        <FormattedMessage id="components.faq.loading" />
      </span>
    );

    const errorMessage = (
      <span className={styles.loadError}>
        <FormattedMessage id="components.faq.loadError" />
      </span>
    );

    const searchedArticles = isSearchView && content.length > 0 ? (
      <Container className={styles.section}>
        {this.getQuestions(content as Array<ArticleViewType>)}
      </Container>
    ) : noResults;

    const sections = !isSearchView
      && content.length > 0
      ? (content as Array<SectionContentiewType>).map((item: SectionContentiewType) => (
        <FAQContentWrapper key={item.sectionTitle} content={item}>
          {this.getQuestions(item.articles)}
        </FAQContentWrapper>
      )) : noResults;

    const headerPos = { top: '13.5%', left: '10px' };
    const markerContentHeight = screenHeight > 800 ? '64vh' : '85vh';
    const AnchorMenuSide = !isLoadError && screenWidth > GeneralSettings.FAQ.ANCHORMENU_DESKTOP
      ? (
        <AnchorMenu
          links={anchorLinks}
          headerPos={headerPos}
          anchorLinkOffsetTop={100}
          isLabelTranslationRequired={false}
          headerItemCustomStyle={{ padding: '20px 0 20px 0' }}
          markerContainerStyle={{ height: markerContentHeight, marginTop: '20px' }}
          headerOffsetTop={61}
        />
      ) : null;

    let contents = null;
    if (!isLoadError) {
      if (loadingItems) {
        contents = loadingMessage;
      } else {
        contents = !isSearchView ? sections : searchedArticles;
      }
    } else {
      contents = errorMessage;
    }

    const contentView = (
      <>
        {AnchorMenuSide}
        {contents}
      </>
    );

    return (
      <>
        {contentView}
      </>
    );
  }
}
