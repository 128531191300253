import React, { useState } from 'react';
import {
  Row, Col, Form,
} from 'react-bootstrap';
// import { FormattedMessage } from 'react-intl';

import FormTextInput from 'components/UI/texts/text';

import styles from './searchForm.module.scss';

interface FormProps {
  onChange: (searchText: string) => void;
}

const MIN_VAL = 0;
const MAX_VAL = 60;

const FAQSearch: React.FC<FormProps> = ({
  onChange,
}) => {
  const [searchVal, setSearch] = useState('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const searchText = e.target.value;
    if (searchText.length >= MIN_VAL && searchText.length < MAX_VAL) {
      setSearch(searchText);
      onChange(searchText);
    }
  };

  const searchRowCl = `mt-2 ${styles.searchInput}`;
  return (
    <>
      <Form noValidate>
        <Row className={searchRowCl}>
          <Col>
            <FormTextInput
              value={searchVal}
              onChange={handleSearchChange}
              controlId="faq-search"
              label=""
              type="text"
              placeholder="Search keywords..."
              inputIcon="search"
              disabled={false}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FAQSearch;
