/* eslint-disable react/no-danger */ // required to get content from zendesk as html
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import expandIcon from 'images/buttons/expand.svg';
import foldIcon from 'images/buttons/fold.svg';

import styles from './Content.module.scss';

interface FAQProps {
  id?: string;
  question: string;
  answer: string;
}

const FAQContent: React.FC<FAQProps> = ({
  question, answer,
}) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const toggleAnswer = (): void => {
    setShowAnswer(!showAnswer);
  };

  let answerContent = null;
  let buttonToggle = expandIcon;
  if (showAnswer) {
    buttonToggle = foldIcon;
    answerContent = (
      <Row>
        <Col className={styles.answer}>
          <div dangerouslySetInnerHTML={{ __html: answer }} />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <div
            className={styles.question}
            role="button"
            tabIndex={0}
            onClick={toggleAnswer}
          >
            <span>{question}</span>
            <div className={styles.toggle}>
              <img src={buttonToggle} alt="toggle" />
            </div>
          </div>
        </Col>
      </Row>
      {answerContent}
      <hr className={styles.breakLine} />
    </>
  );
};

export default FAQContent;
