import React from 'react';

import Index, { IndexProps } from 'components/layout';
import SEO from 'components/seo';
import PageWrapper from 'components/PageWrapper';
import FAQWrapper from 'components/FAQ/Wrapper';

const FAQPage: React.FC<IndexProps> = ({ pageContext }) => (
  <Index pageContext={pageContext}>
    <SEO pageTitleTermPath="faq" />
    <PageWrapper withBottomBackground>
      <FAQWrapper />
    </PageWrapper>
  </Index>
);

export default FAQPage;
