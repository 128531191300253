import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { SectionContentiewType } from 'types/faq';

import mSignal, { SIGNALS } from 'helpers/constants/signals';
import { removeHashSignFromLink } from 'helpers/utils/url';
import { isDivTop } from 'helpers/utils/html';

import cmnStyles from '../Contents/Contents.module.scss';

interface FAQCOntentWrapperProps {
  content: SectionContentiewType;
}

const FAQContentWrapper: React.FC<FAQCOntentWrapperProps> = ({
  children,
  content,
}) => {
  useEffect(() => {
    const trackScrolling = (): void => {
      const wrappedElement = document.getElementById(removeHashSignFromLink(content.hashTag));
      if (isDivTop(wrappedElement)) {
        mSignal.dispatch(SIGNALS.ACTIVE_HUMAN_SCROLL, content.hashTag);
      }
    };

    document.addEventListener('scroll', trackScrolling);

    return (): void => {
      document.removeEventListener('scroll', trackScrolling);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      id={removeHashSignFromLink(content.hashTag)}
      className={cmnStyles.section}
      key={content.sectionTitle}
    >
      <div className={cmnStyles.sectionHeader}>
        <div className={cmnStyles.headerOval}> </div>
        <span className={cmnStyles.sectionTitle}>
          {content.sectionTitle}
        </span>
      </div>
      {children}
    </Container>
  );
};

export default FAQContentWrapper;
