import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import FAQIntro from '../Intro';
import FAQContents from '../Contents';
import styles from './Wrapper.module.scss';

const FAQWrapper: React.FC = () => {
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');
  const onSearchTextChange = (term: string): void => {
    setSearchText(term);
  };
  return (
    <Container className={styles.container}>
      <FAQIntro onSearchChange={onSearchTextChange} />
      <FAQContents searchTerm={searchText} locale={intl.locale} />
    </Container>
  );
};

export default FAQWrapper;
